import { runInAction } from 'mobx'

import { http, format } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const toHour = (user, data) => {
  const minute_total = data.minute_total || 0
  const { hour, minute } = format.getWorkTime(minute_total)
  const dateList = data.date_list || []

  const date_list = dateList.map((it) => {
    const { hour, minute } = format.getWorkTime(it.minute_total)
    it.hour = hour
    it.minute = minute
    return it
  })
  return {
    ...user,
    hour,
    minute,
    minute_total,
    date_list,
  }
}

const toFilterList = (params = {}, list = []) => {
  const { user_id, employee_type } = params
  if (!user_id && !employee_type) return list

  const resultList = []
  for (const item of list) {
    const isUser = user_id ? item.user_id === user_id : true
    const isType = employee_type ? item.employee_type === employee_type : true

    if (isUser && isType) {
      resultList.push(item)
    }
  }

  return resultList
}

const toList = (list = [], userList = []) => {
  const resultList = userList.map((it) => {
    const { user_id } = it
    const i = list.findIndex((data) => data.user_id === user_id)
    const data = i === -1 ? {} : list[i]

    return toHour(it, data)
  })

  return resultList
}

const Url = `${config.api}/v1/user-admin/timesheet/report/summary`
let state
export class SummaryTimesheet extends BaseStore {
  constructor() {
    super()
    this.observable({
      summary: {
        page: {
          index: 1,
          per_page: 20,
          total: 0,
        },
        list: [],
      },
    })
    state = this
  }

  async getList(query, employeeList = []) {
    const { index = 1, per_page = 20, date } = query

    const userList = toFilterList(query, employeeList)
    const i = (index - 1) * per_page
    const displayList = userList.slice(i, i + per_page)
    const user_list = displayList.map((it) => it.user_id)
    const json = {
      index,
      per_page,
      month: date.format('MMYY'),
      user_list,
    }
    const url = `${Url}`
    const res = await http.post(url, { json })

    const list = res.body || []

    const resultList = toList(list, displayList)
    runInAction(() => {
      state.summary = {
        page: {
          index,
          per_page,
          total: userList.length,
        },
        list: resultList,
      }
    })
  }

  async getExportList(query, employeeList = []) {
    const { index = 1, per_page = 20, date } = query

    const json = {
      index,
      per_page,
      month: date.format('MMYY'),
      user_list: [],
    }
    const url = `${Url}`
    const res = await http.post(url, { json })

    const list = res.body || []
    const resultList = toList(list, employeeList)

    return resultList
  }
}

export default new SummaryTimesheet()
